<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">{{ $t("labels.set_permission") }}</span>
    </v-card-title>
    <v-card-text style="max-height: 600px; overflow-y: auto">
      <div class="mb-5 d-flex align-center">
        <v-btn
          class="mr-2"
          color="success"
          v-for="item in warehouses"
          :key="`pwh_${item.id}`"
          :outlined="item.id != id_warehouse"
          @click="changeWarehouse(item.id)"
        >
          {{ item.code_name }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn color="primary" @click="updatePermission">
          {{ $t("labels.save") }}
        </v-btn>
      </div>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="4"
          v-for="item in permissions"
          :key="`p_${item.value}`"
        >
          <v-checkbox
            dense
            hide-details
            class="c-input-small"
            v-model="selectedPermissions"
            :value="item.value"
            :label="item.text"
            multiple
            @change="onPermissionChanged"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { formatDateTime } from "@/libs/helpers";

export default {
  name: "Permission",
  props: {
    employee: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    warehouses: [],
    id_warehouse: null,
    detail: {},
    permissions: [],
    selectedPermissions: [],
    isChanged: false,
  }),
  computed: {},
  methods: {
    formatDateTime,
    closeDialog() {
      this.$emit("closeDialog");
    },
    refreshData() {
      this.$emit("refreshData");
    },
    onPermissionChanged() {
      this.isChanged = true;
    },
    getEmployeeWarehouses() {
      httpClient
        .post("/employee/v1/detail", {
          id: this.employee.id,
        })
        .then(({ data }) => {
          this.detail = { ...data.employee };
          this.warehouses = [...data.warehouses];
          this.id_warehouse =
            (this.warehouses && this.warehouses[0] && this.warehouses[0].id) ||
            0;
          if (this.id_warehouse) {
            this.getPermissions();
          }
        });
    },
    changeWarehouse(id_warehouse) {
      if (
        this.isChanged &&
        !confirm(
          "Có thay đổi nhưng chưa lưu? Bạn có chắc chắn muốn chuyển tab?"
        )
      ) {
        return false;
      }
      this.id_warehouse = id_warehouse;
      this.getPermissions();
    },
    async getPermissions() {
      const { data } = await httpClient.post(
        "/employee/v1/permission-by-warehouse",
        {
          id_object: this.id_warehouse,
          id_identity: this.detail.id_identity,
        }
      );
      this.isChanged = false;
      this.selectedPermissions = [...data.active_permissions].map((p) => p.id);
      this.permissions = [...data.permissions].map((e) => ({
        value: e.id,
        text: this.$i18n.locale === "vi" ? e.description : e.description_en,
      }));
    },
    async updatePermission() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post("/employee/v1/set-permission-by-warehouse", {
          id_object: this.id_warehouse,
          id_identity: this.detail.id_identity,
          selected_permissions: this.selectedPermissions,
        });
        this.isLoading = false;
        this.isChanged = false;
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
  mounted() {
    this.getEmployeeWarehouses();
  },
};
</script>

<style scoped lang="scss"></style>
